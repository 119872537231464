import React from "react"
import styled from "styled-components"

const StyledWrapper = styled.li`
  flex: 1;
  margin-right: 30px;

  :nth-child(n + 3) {
    margin-right: 0;
  }

  @media screen and (max-width: 1024px) {
    flex: 1 100%;
  }
`

const StyledImage = styled.div`
  width: 100%;
  height: 340px;
  margin-bottom: 16px;
  background-image: url(${({ image }) => image});
  background-repeat: no-repeat;
  background-position: left;

  @media screen and (max-width: 1024px) {
    background-position: center;
  }
`

const AccessoriesElement = ({ name, children, image }) => (
  <StyledWrapper className="mb16">
    <StyledImage image={image} />
    <h2 className="header4 no-margin">{name}</h2>
    {children}
  </StyledWrapper>
)

export default AccessoriesElement
