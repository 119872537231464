import React from "react"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"
import ImageWithText from "../../Molecules/ImageWithText/ImageWithText"
import { localize } from "../../Atoms/Language"

const imageQuery = graphql`
  {
    file(name: { eq: "carwash8" }) {
      childImageSharp {
        fixed(width: 445, height: 562, quality: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`

const StyledImageWithText = styled(ImageWithText)`
  .image {
    max-width: 445px !important;
    height: 562px;
    padding: 0;
  }

  .text-content {
    padding-right: 125px;

    ul {
      margin-left: 20px;
    }
  }

  @media screen and (max-width: 1124px) {
    .image {
      max-width: 40% !important;
      height: auto;
    }
  }

  @media screen and (max-width: 1024px) {
    .text-content {
      padding-right: 20px;
    }
  }

  @media screen and (max-width: 900px) {
    .text-content {
      max-width: 100%;
    }
    .image {
      display: none;
    }
  }
`

const Accessories = () => {
  const data = useStaticQuery(imageQuery)

  return (
    <StyledImageWithText
      fixed={data.file.childImageSharp.fixed}
      className="mt8 mb8"
      imageAltText={localize("7")}
    >
      <h1 className="header2">{localize("7")}</h1>
      <p className="body">
        {localize("23")}
      </p>

      <p className="body no-margin">
        {localize("24")}
      </p>
      <ul className="no-margin gray600">
        <li>{localize("25")}</li>
        <li>{localize("26")}</li>
        <li>{localize("27")}</li>
      </ul>

      <p className="body">
        {localize("28")}
      </p>

      <p className="body">
        {localize("29")}
      </p>
    </StyledImageWithText>
  )
}

export default Accessories
