import React from "react"
import Accessories from "../components/Organisms/StaticSections/Accessories"
import CTABanner from "../components/Organisms/CTABanner/CTABanner"
import AccessoriesList from "../components/Organisms/StaticSections/AccessoriesList"
import SEO from "../components/seo"
import { localize } from "../components/Atoms/Language"
import { WithIntl } from "../components/Atoms/withTranslate"

const AccessoriesPage = ({language}) => (
  <>
    <SEO
      lang={language}
      title={localize("40")}
      description={localize("41")}
    />
    <Accessories />
    <div className="container">
      <AccessoriesList />
      <CTABanner language={language} />
    </div>
  </>
)

export default WithIntl()(AccessoriesPage)
