import React from "react"
import Grid from "../../Atoms/Grid"
import AccessoriesElement from "../../Atoms/AccessoriesElement"
import odkurzacz from "../../../assets/images/odkurzacz.png"
import trzepaczka from "../../../assets/images/trzepaczka.png"
import rozmieniarka from "../../../assets/images/rozmieniarka.png"
import { localize } from "../../Atoms/Language"

const AccessoriesList = () => (
  <Grid>
    <AccessoriesElement
      name={localize("30")}
      image={odkurzacz}
      alt={localize("30")}
    >
      <p className="body-small gray600">
        {localize("31")}
      </p>
    </AccessoriesElement>
    <AccessoriesElement
      name={localize("32")}
      image={trzepaczka}
      alt={localize("32")}
    >
      <p className="body-small gray600">{localize("33")}</p>
    </AccessoriesElement>
    <AccessoriesElement
      name={localize("34")}
      image={rozmieniarka}
      alt={localize("34")}
    >
      <p className="body-small gray600">{localize("35")}</p>
      <p className="body-small gray600">{localize("36")}</p>
    </AccessoriesElement>
  </Grid>
)

export default AccessoriesList
